<template>
  <div class="pt-4">
    <v-row>
      <v-col cols="12" class="col-sm-4">
        <v-row no-gutters>
          <v-col cols="12" class="col-sm-12 d-flex justify-space-between align-center py-4 white">
            <div class="d-flex align-center text-h5 font-weight-bold">
              <v-icon class="mr-2" large>
                mdi-history
              </v-icon>
              History
            </div>
            <v-btn class="rounded-lg secondary--text text--lighten-5 text-capitalize" depressed color="primary" @click.prevent="CERTIFICATE_GENERATE_SHOW()">
              Generate New Certificate
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-4">
            <v-data-table
              dense
              :headers="headersHistory"
              :items="tableHistory"
              item-key="id"
              :server-items-length="tableTotalHistory"
              :page.sync="tablePageHistory"
              :items-per-page.sync="tableLimitHistory"
              :options.sync="optionsHistory"
              :footer-props="{
                itemsPerPageOptions: [25, 50, 100]
              }"
              :loading="tableLoadingHistory"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.created`]="{ item }">
                <div class="py-1">
                  {{ item.created ? $localDT(item.created, 'datetimedefaultdb') : '-' }}
                  <div class="caption">
                    {{ item.certificate_brand_name || '#' }}, {{ item.certificate_product_name || '#' }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                <div class="py-2">
                  <div>
                    {{ item.quantity }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.option`]="{ item }">
                <div class="py-2 d-flex justofy-space-between">
                  <!-- <v-btn :disabled="item.labeled ? true : false" x-small depressed color="primary" class="text-capitalize" @click.prevent="ImportFileExcel(item)">
                    Uplad SN
                  </v-btn>
                  <v-divider vertical class="mx-1" /> -->
                  <v-btn x-small depressed color="indigo" dark class="text-capitalize" @click.prevent="DOWNLOAD_CERTIFICATES(item)">
                    Download
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="col-sm-1 d-flex justify-center">
        <v-divider vertical class="fill-height" />
      </v-col>
      <v-col cols="12" class="col-sm-7">
        <v-row no-gutters>
          <v-col cols="12" class="col-sm-12 white">
            <div class="pt-4 pb-0 pb-sm-4">
              <v-text-field
                v-model="tableSearch"
                outlined
                hide-details
                placeholder="Search certificate or serial number ..."
                prepend-inner-icon="mdi-magnify"
              />
            </div>
          </v-col>
          <v-col cols="12" class="pt-4">
            <v-data-table
              dense
              :headers="headers"
              :items="table"
              item-key="id"
              :server-items-length="tableTotal"
              :page.sync="tablePage"
              :items-per-page.sync="tableLimit"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [15, 50, 100]
              }"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.certificate`]="{ item }">
                <div class="font-weight-bold py-1">
                  {{ item.certificate }}
                </div>
              </template>
              <template v-slot:[`item.certificate_product_number`]="{ item }">
                <div>
                  {{ item.certificate_product_number || '-' }}
                </div>
              </template>
              <template v-slot:[`item.certificate_product_name`]="{ item }">
                <div class="py-1">
                  {{ item.certificate_product_name || '-' }}
                </div>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                <div>
                  {{ item.created ? $localDT(item.created, 'datetimedefaultdb') : '-' }}
                </div>
              </template>
              <template v-slot:[`item.printed`]="{ item }">
                <div>
                  {{ item.printed ? $localDT(item.printed, 'datetimedefaultdb') : '-' }}
                </div>
              </template>
              <template v-slot:[`item.labeled`]="{ item }">
                <div>
                  {{ item.labeled ? $localDT(item.labeled, 'datetimedefaultdb') : '-' }}
                </div>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="py-2">
                  <div>
                    {{ item.name }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.option`]="{ item }">
                <div class="py-2">
                  <v-btn :disabled="parseInt(item.product) ? true : false" x-small block outlined color="primary" class="text-capitalize" @click.prevent="SIGN_PRODUCT_CERTIFICATE(item)">
                    Sign Product
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogGenerator.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogGenerator.data" >
        <v-card-title class="body-1 justify-center">
          Generate New Certificate
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-select
            v-model.number="dialogGenerator.data.brand"
            :items="$store.state.brandData"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            label="Brand"
            class="mb-4"
            @change="LOAD_PRODUCT()"
          />
          <v-select
            v-model.number="dialogGenerator.data.product"
            :disabled="parseInt(dialogGenerator.data.brand) ? false : true"
            :items="productBrandList"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            label="Product"
            class="mb-4"
          />
          <v-text-field
            v-model.number="dialogGenerator.data.certLength"
            outlined
            dense
            hide-details
            label="Quantity"
            class="mb-4"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogGenerator.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="CERTIFICATE_GENERATE()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSignProduct.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogSignProduct.data && dialogSignProduct.selected" >
        <v-card-title class="body-1 flex-column align-center justify-center">
          Sign Certificate Product
          <div class="caption font-weight-bold">
            - {{ dialogSignProduct.selected.certificate }} -
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-select
            v-model.number="dialogSignProduct.data.product"
            :items="$store.state.productData"
            :item-text="(p) => {
              return p.name + ' - ' + p.brand_name
            }"
            item-value="id"
            outlined
            hide-details
            label="Product"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogSignProduct.data.certificate_product_number"
            outlined
            dense
            hide-details
            label="Serial Number"
            class="mb-4"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogSignProduct.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="SIGN_PRODUCT_CERTIFICATE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="false"
      v-model="dialogCertificate.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogStore.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStore.data.id ? 'Update Branch' : 'Create New Branch' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="dialogStore.data.name"
            outlined
            dense
            hide-details
            label="Name"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogStore.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4"
            @keypress="$NumOnly"
          />
          <v-textarea
            v-model="dialogStore.data.address"
            rows="2"
            outlined
            dense
            hide-details
            label="Full Address"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogStore.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Unit Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogStore.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="STORE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import readXlsxFile from 'read-excel-file'
let searchInterval = null
export default {
  name: 'Certificate',
  data: () => ({
    headers: [
      {
        text: 'Serial Number',
        value: 'certificate_product_number',
        sortable: true
      },
      {
        text: 'Certificate',
        value: 'certificate',
        sortable: true
      },
      // { text: 'Product SKU', value: 'certificate_product_number', sortable: false },
      { text: 'Brand', value: 'certificate_brand_name', sortable: false },
      { text: 'Product', value: 'certificate_product_name', sortable: false },
      { text: 'Generated', value: 'created', sortable: true },
      { text: 'Printed / Downloaded', value: 'printed', sortable: true }
      // { text: 'Status', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 15,
    options: {},
    headersHistory: [
      {
        text: 'Payload',
        value: 'created',
        sortable: false
      },
      { text: 'Quantity', value: 'quantity', sortable: false },
      { text: 'Option', value: 'option', sortable: false }
    ],
    tableSearchHistory: '',
    tableLoadingHistory: false,
    tableHistory: [],
    tableTotalHistory: 0,
    tablePageHistory: 1,
    tableLimitHistory: 25,
    optionsHistory: {},
    dialogCertificate: {
      show: false,
      data: null
    },
    dialogGenerator: {
      show: false,
      data: null
    },
    dialogSignProduct: {
      show: false,
      selected: null,
      data: null
    },
    productBrandList: []
  }),
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.CERTIFICATE_GET(q)
      }, 300)
    },
    options: function () {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.CERTIFICATE_GET(this.tableSearch)
      }, 300)
    }
  },
  mounted () {
    this.CERTIFICATE_GET()
    this.CERTIFICATE_HISTORY_GET()
  },
  methods: {
    LOAD_PRODUCT () {
      this.productBrandList = []
      const brandID = this.dialogGenerator.data ? parseInt(this.dialogGenerator.data.brand) : 0
      if (brandID) {
        this.dialogGenerator.data.product = 0
        this.$store.dispatch('product/PRODUCT_GET', '?page=0&limit=1000&brand=' + brandID)
          .then((res) => {
            if (res.status) {
              this.productBrandList = res.data.data.data || []
            }
          })
      }
    },
    SIGN_PRODUCT_CERTIFICATE (cert) {
      this.dialogSignProduct.selected = Object.assign({}, cert)
      this.dialogSignProduct.data = Object.assign({}, cert)
      this.dialogSignProduct.show = true
    },
    SIGN_PRODUCT_CERTIFICATE_PROCESS () {
      if (!confirm('Sign product for this certificate?')) {
        return false
      }
      const data = Object.assign({}, this.dialogSignProduct.data)
      if (!parseInt(data.product)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select product!' })
        return false
      }
      if (!data.certificate_product_number) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input product number!' })
        return false
      }
      this.$store.dispatch('certificate/CERTIFICATE_SIGN', {
        certificate: data.certificate,
        product: parseInt(data.product),
        certificate_product_number: data.certificate_product_number,
        labeled: (new Date()).toISOString()
      }).then((res) => {
        if (res.status) {
          this.$store.dispatch('TOAST', { show: true, message: 'Successfully signed!' })
          this.dialogSignProduct.show = false
          this.CERTIFICATE_GET()
          this.CERTIFICATE_HISTORY_GET()
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
        }
      })
    },
    async DOWNLOAD_CERTIFICATES (cert) {
      if (!confirm('Are you sure want to download this certificates ?')) {
        return false
      }
      const requestData = await this.CERTIFICATE_GET('', cert.brand, this.$localDT(cert.created, 'datetimedefaultdb'))
      if (requestData && requestData.status && requestData.data.data.data) {
        const headers = ['Nomor', 'Serial_Code', 'QR_Code']
        const rows = []
        const data = requestData.data.data.data || []
        for (let d = 0; d < data.length; d++) {
          const newRow = data[d]
          rows.push([
            {
              type: Number,
              value: d + 1
            },
            {
              type: String,
              value: newRow.certificate_product_number || ''
            },
            {
              type: String,
              value: newRow.certificate || '-'
            }
          ])
        }
        const fileName = (cert.certificate_brand_name || '').toLowerCase().split(' ').join('-')
        await this.$CreateExcelFile({
          headers: headers,
          rows: rows
        }, 'certificates-' + fileName + '-' + this.$localDT(cert.created, 'datetimedefaultdb').split(':').join('-') + '.xlsx')
        await this.$store.dispatch('certificate/CERTIFICATE_PRINTED', {
          brand: cert.brand,
          created: cert.created
        }).then((res) => {
          if (res.status) {
            this.CERTIFICATE_GET()
            this.CERTIFICATE_HISTORY_GET()
          }
        })
      }
    },
    CERTIFICATE_GENERATE_SHOW () {
      this.dialogGenerator.data = Object.assign({}, {
        brand: 0,
        product: 0,
        certLength: 0
      })
      this.dialogGenerator.show = true
    },
    CERTIFICATE_GENERATE () {
      if (!confirm('Generate new certificates ?')) {
        return false
      }
      const data = Object.assign({}, this.dialogGenerator.data)
      if (!parseInt(data.brand)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select brand!' })
        return false
      }
      if (!parseInt(data.product)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select product!' })
        return false
      }
      if (!parseInt(data.certLength)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input quantity!' })
        return false
      }
      this.$store.dispatch('certificate/CERTIFICATE_GENERATE', { brand: data.brand, certLength: data.certLength, product: parseInt(data.product) || 0 })
        .then((res) => {
          if (res.status) {
            this.dialogGenerator.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Generated.' })
            this.CERTIFICATE_GET()
            this.CERTIFICATE_HISTORY_GET()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    CERTIFICATE_SHOW (certificate) {
      this.dialogCertificate.data = Object.assign({}, certificate)
      this.dialogCertificate.show = true
    },
    async CERTIFICATE_GET (q, brand, created) {
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy ? (sortBy.length ? '&sortby=c.' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : '')
      query += parseInt(brand) ? ('&brand=' + parseInt(brand)) : ''
      query += created ? ('&created=' + created) : ''
      return await this.$store.dispatch('certificate/CERTIFICATE_GET', query + (q ? ('&q=' + q) : '')).then((res) => {
        this.tableLoading = false
        if (brand && created) {
          if (res.status) {
            return res
          }
        } else {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
        }
      })
    },
    CERTIFICATE_HISTORY_GET (q) {
      this.tableLoadingHistory = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsHistory
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : '')
      this.$store.dispatch('certificate/CERTIFICATE_HISTORY_GET', query + (q ? ('&q=' + q) : '')).then((res) => {
        if (res.status) {
          this.tableHistory = res.data.data.data
          this.tableTotalHistory = parseInt(res.data.data.total) || 0
        } else {
          this.tableHistory = []
          this.tableTotalHistory = 0
        }
        this.tableLoadingHistory = false
      })
    },
    ImportFileExcel (certs) {
      if (!confirm('Import and generate serial number ?')) {
        return false
      }
      let el = document.getElementById('import-serial-number-excel')
      if (el) {
        el.parentNode.removeChild(el)
      }
      el = document.createElement('input')
      el.setAttribute('id', 'import-serial-number-excel')
      el.setAttribute('class', 'file-upload')
      el.setAttribute('type', 'file')
      el.addEventListener('change', (event) => {
        const xlsxfile = event.target.files ? event.target.files[0] : null
        readXlsxFile(xlsxfile).then((rows) => {
          const data = []
          for (let r = 0; r < rows.length; r++) {
            const newR = {}
            newR.Nomor = r
            newR.Serial_Code = rows[r][1] || ''
            newR.QR_Code = rows[r][2] || ''
            newR.Produk_ID = parseInt(rows[r][3]) || 0
            if (r > 0) {
              data.push(newR)
            }
          }
          console.log(certs)
          console.log(data)
          console.log('-----')
          if (parseInt(certs.quantity) === data.length) {
            if (confirm('Generate certificates from uploaded data ?')) {
              this.$store.dispatch('certificate/CERTIFICATE_GENERATE_SYNC', {
                brand: certs.brand,
                created: this.$localDT(certs.created, 'datetimedefaultdb'),
                data
              }).then((res) => {
                if (res.status) {
                  if (res.data.data.status) {
                    this.$store.dispatch('TOAST', { show: true, message: res.data.data.message || 'Success!' })
                    this.CERTIFICATE_GET()
                    this.CERTIFICATE_HISTORY_GET()
                  } else {
                    this.$store.dispatch('TOAST', { show: true, message: res.data.data.message || 'Failed!' })
                  }
                } else {
                  this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
                }
              })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Uploaded rows of data is not match with quantity!' })
          }
        })
      })
      el.click()
    }
  }
}
</script>
